<template>
  <div>
    <!-- TODO: Edit admin figures -->
    <page-title>Dashboard</page-title>
    <page-subtitle>Welcome, {{ user.firstName }}</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-2 md:p-5 rounded md:flex w-full md:space-x-6">
        <div class="grid col-span-2 grid-cols-2 gap-5 w-full">
          <div
            v-for="(list, i) in lists"
            :key="i"
            class="card-amount bg-gray-50 p-5 rounded-md"
          >
            <p class="font-bold text-base md:text-2xl">
              {{ list.type === 'number' ? '' : 'N' }}
              {{ numberWithCommas(list.amount) }}
            </p>
            <div class="flex flex-shrink-0">
              <img />
              <span class="md:text-sm text-xs font-semibold">{{
                list.text
              }}</span>
            </div>
          </div>
        </div>

        <div class="client-list md:mt-0 mt-4 bg-gray-50 p-5">
          <section-subtitle class="mb-2">Clients</section-subtitle>
          <!-- <div class="flex flex-row">
            <t-input type="search" />
          </div> -->
          <div
            v-for="client in clients"
            :key="client._id"
            class="flex my-2 justify-between items-center"
          >
            <span class="text-sm font-medium"
              >{{ client.lastName }} {{ client.firstName }}</span
            >
            <router-link
              :to="{ name: 'ClientProfile', params: { id: client._id } }"
              class="text-sm cursor-pointer text-primary font-semibold"
              >view</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle.vue'
import clientApi from '../../api/client/index'
import invoice from '../../api/invoice'
import { numberWithCommas } from '../../utils/helpers'

export default {
  name: 'DashboardAdmin',
  components: { PageTitle, PageSubtitle, SectionSubtitle },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created() {
    this.getClients()
    this.getInvoice()
  },
  data: () => ({
    numberWithCommas,
    lists: [
      {
        amount: '',
        text: 'Number of Clients',
        type: 'number'
      },
      {
        amount: '0',
        text: 'Invoice Amount'
      },
      {
        amount: '0',
        text: 'Amount Paid'
      },
      {
        amount: '0',
        text: 'Pending Amount'
      }
    ],
    clients: []
  }),
  methods: {
    async getClients() {
      const res = await clientApi.client().getAll({ limit: 6 }, false)
      this.lists[0].amount = res.data.meta.total
      this.clients = res.data.data
    },
    async getInvoice() {
      const res = await invoice.invoice().getAllWithLimit({ limit: 0 })
      this.lists[1].amount = res.data.data.reduce((a, b) => a + b.amount, 0)
      this.lists[2].amount = res.data.data
        .filter(c => c.status === 'completed')
        .reduce((a, b) => a + b.amount, 0)
      this.lists[3].amount = res.data.data
        .filter(c => c.status === 'pending')
        .reduce((a, b) => a + b.amount, 0)
    }
  }
}
</script>

<style></style>
